@keyframes arrowAnnimation {
  0% {
    left: -100%;
    right: 100%;
    opacity: 0;
  }
  25% {
    opacity: 0.25;
  }
  75% {
    opacity: 0.50;
  }
  100% {
    left: 0%;
    right: 0%;
    opacity: 1;
  }
}

@keyframes arrowAnnimationUnmount {
  from {
    opacity: 1;
    left: 0%;
    right: 0%;
  }
  to {
    opacity: 0;
    left: -100%;
    right: 100%;
  }
}
