.sectionThree {
  background-color: #fff;
  padding: 4rem;
  position: relative;
}

.sectionThree-container {
  justify-content: space-between;
  display: flex;
  flex-flow: row;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}
@media only screen and (max-width: 1000px) {
  .sectionThree-container {
    display: flex;
    flex-direction: column;
    padding: 50px 0px;
  }
  .why-chose-us-decoration {
    display: none !important;
  }
  .sectionThree {
    padding: 0px !important;
  }
  .sectionThree-info {
    margin: 0px !important;
  }
  .why-chose-us-img {
    width: 100% !important;
  }
}
.setionThree-text {
  color: #555555;
  font-family: "Assistant", Sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: justify;
}
.sectionThree-info {
  margin: 60px;
  margin-left: -100px;
  margin-right: 100px;
}

.sectionThree-title1 {
  color: var(--themeColor);
  font-family: "Oswald", Sans-serif;
  font-size: 26px;
  font-weight: 400;
  margin-bottom: 10px;
}
.sectionThree-title2 {
  color: #131c3b;
  font-family: "Oswald", Sans-serif;
  font-size: 48px;
  margin-bottom: 40px;
  font-weight: 700;
}
.sectionThree-img {
  position: relative;
  z-index: 10;
  border-radius: 20px;
  overflow: hidden;
  width: 100%;
  height: 378px;
}

.secThree-list {
  font-family: "Assistant", Sans-serif;
  font-size: 19px;
  font-weight: 600;
  color: #131c3b;
  padding-left: 15px;
  margin: 30px;
}

.secThree-listItem {
  margin: 10px;
}

.why-chose-us-img {
  position: relative;
  width: 2340px;

  max-width: 584px;
}

.secThree-image-decoration1 {
  position: absolute;
  top: -20px;
  right: -20px;
  z-index: 0;
}
.why-chose-us-decoration {
  position: absolute;
  background-color: var(--themeColor);
  height: 100%;
  width: 35%;
  right: 0px;
  top: 0;
}
.secThree-image-decoration2 {
  position: absolute;
  bottom: -25px;
  left: -25px;
  z-index: 0;
}
