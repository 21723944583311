.PhotoSection {
  max-width: 1150px;
  background-color: #333;
  margin: auto;
  padding: 50px;
  display: flex;
  gap: 10px;
}

.photoSectionContainer{
  background-color: #333;
}

@media only screen and (max-width: 1000px) {
  .PhotoSection {
flex-direction: column;
  }
  .PhotoSectionLeft {
    width: 100% !important;
  }
  .PhotoSectionRight {
    width: 100% !important;
  }
  .PhotoSectionSlider{
    height: 250px !important;
    width: 100% !important;
  }
}

.PhotoSectionLeft {
  width: 50%;
  position: relative;
}

.PhotoSectionTitleContainer {
  position: relative;
  width: 95%;
}

.PhotoSectionTitleContainer .PhotoSectionTitleDecoration1 {
  position: absolute;
  height: 50px;
  width: 50px;
  top: -20px;
  left: -20px;
  border-left: 2px solid var(--themeColor);
  border-top: 2px solid var(--themeColor);
}
.PhotoSectionTitleContainer .PhotoSectionTitleDecoration2 {
  position: absolute;
  height: 50px;
  width: 50px;
  bottom: -20px;
  right: -20px;
  border-right: 2px solid var(--themeColor);
  border-bottom: 2px solid var(--themeColor);
}
.PhotoSectionTitleContainer .PhotoSectionTitle {
  font-family: raleway, sans-serif;
  font-weight: 700;
  line-height: 1.3;
  word-break: break-word;
  color: white;
}

.PhotoSectionContentContainer {
}
.PhotoSectionContentContainer .PhotoSectionContent {
  color: #a4a4a4;
  margin: 50px 0px;
  width: 90%;
}
.PhotoSectionContentContainer .PhotoSectionButton {
  text-decoration: none;
  color: black;
  background-color: var(--themeColor);
  padding: 10px;
  display: block;
  width: fit-content;
  margin-bottom: 50px;
}

.PhotoSectionSlider {
  height: 175px;
  width: 115%;
}

.PhotoSectionRight {
  width: 50%;
}

.PhotoSectionRight .PhotoSectionImage {
  width: 100%;
  height: 100%;
  max-height: 560px;
  background-color: black;
  object-fit: cover;
}
