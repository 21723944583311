.sectionSix-card{
    background-color:#fff;
    width: 100%;
    text-align: center;
    border-radius: 10px;
    padding: 50px 60px;
    min-width: 100%;
    position: relative;
  }
  .image-card-hover{
   position: absolute;
   width: 115px;
   height: 115px;
   background-color: black;
   top: -60px;
   left: 0px;
   right: 0px;
   margin: auto;
   border-radius: 50%;   
   overflow: hidden;
  }
  .sectionSix-card-header{
    margin-top: 20px;
    font-size: 21px;
  }
  
  .sectionSix-card-title{
    margin-top: 20px;
    font-size: 16px;
  }
  .sectionSix-card-text{
    margin-top:20px;
    font-size: 16px;
    width: 100%;
    overflow-wrap: break-word;
  }