.sectionFive {
  padding: 4rem;
  background-color: white;
}
.sectionFive-title {
  color: #131c3b;
  font-family: oswald, Sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 59px;
}
.sectionFive-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  max-width: 1080px;
  margin: auto;
  height: fit-content;
}

@media only screen and (max-width: 500px) {
  .sectionFive-container {
    grid-template-columns: 1fr;
  }
  .sectionFive-info {
    grid-column-start: span 1;
  }
}
@media only screen and (min-width: 500px) and (max-width: 1000px) {
  .sectionFive-container {
    grid-template-columns: 1fr 1fr;
  }
  .sectionFive-info {
    grid-column-start: span 2;
  }
}

.sectionFive-items {
  /* margin-bottom: 24px; */
  display: flex;
}
.secFive__item {
  aspect-ratio: 0.70;
  background-repeat: no-repeat;
  background-size: cover;
  transition-duration: 5;
  border-radius: 10px;
  position: relative;
}
.secFive__backDrop {
  transition: all 0.5s ease;
  color: white;
  z-index: 500;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
}
.secFive__backDrop:hover {
  transition: all 0.5s ease;
  color: white;
  z-index: 500;
  width: 100%;
  height: 100%;
  background-color: var(--themeColorTransparent);
  opacity: 1;
}

.secFour__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  text-decoration: none;
}
.secFive__item__text {
  font-size: 23px;
  font-weight: 400;
  margin: 0;
  position: relative;
  top: 150px;
}
.sectionFive-text {
  margin-top: 30px;
  color: #555;
  font-family: assistant, Sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: justify;
  width: 85%;
}


.sectionFive-info {
  font-size: 18px;

  line-height: 24px;
}
@media only screen and (min-width: 1000px) {
  .sectionFive-info {
grid-column-start: span 2;
  }
}
.sectionFive-button {
  margin-top: 50px;
  border: none;
  font-family: assistant, Sans-serif;
  font-size: 20px;
  font-weight: 600;
  fill: #fff;
  color: #fff;
  background-color: var(--themeColor);
  border-radius: 0 0 0 0;
  padding: 20px 30px;
}

.sectionFive-button:hover {
  background-color: #131c3b;
  transition: all 0.5s ease;
}
