.sliderHeader .slick-dots {
  bottom: 0px;
  color: white;
}


.slick-dots li button:before
{
    font-family: 'slick';
    font-size: 20px !important;
    line-height: 0px !important;
}

.sliderHeader .slick-dots li button:before {
  color: white;
  opacity: 1;
}
.sliderHeader .slick-dots li.slick-active button:before {
  color: var(--themeColor);
}
