.footer-container {
  background-color: #242424;
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.spacecode {
  clear: both;
  height: 10px;
}

.footer-input {
  padding: 8px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid #fff;
}

.footer-links {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
}
.footer-links-scroll-animation {
  color: white;
  padding: 5px 0px;
  cursor: pointer;
}
.footer-links-scroll-animation:hover {
  transition: all;
  transition-duration: 100ms;
  color: var(--themeColor);
}

.contactInfoIcons {
  position: relative;
}

.ContactIconImage {
  position: absolute;
  width: 20px;
  aspect-ratio: 1 / 1;
  left: -45px;
  top: 0px;
}

.footer-link-wrapper {
  display: grid;
  gap: 100px;
}
@media only screen and (max-width: 750px) {
  .footer-link-wrapper {
    grid-template-columns: 1fr;
  }
  .footer-link-items {
    padding: 0px !important;
  }
}
@media only screen and (min-width: 750px) and (max-width: 1000px) {
  .footer-link-wrapper {
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (min-width: 1000px) and (max-width: 1500px) {
  .footer-link-wrapper {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media only screen and (min-width: 1500px) {
  .footer-link-wrapper {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* margin: 50px; */
  text-align: left;
  width: 250px;
  box-sizing: border-box;
  position: relative;
}

.footer-link-items h2 {
  margin-bottom: 16px;
}

.footer-link-items > h2 {
  color: #fff;
}

.footer-link-items a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}

.footer-email-form h2 {
  margin-bottom: 2rem;
}

.footer-input::placeholder {
  color: #b1b1b1;
}

/* Social Icons */
.social-icon-link {
  color: #fff;
  font-size: 24px;
}

.social-media {
  max-width: 100%;
  width: 100%;
  border-top: solid 1px #333130;
  text-align: center;
}
.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
}

.website-rights {
  color: #fff;
  margin-top: 50px;
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
}

.FooterCellDecoration {
  width: 100px;
  height: 300px;
  display: flex;
  flex-direction: column;
  position: relative;
}


.LocationPro {
  color: white !important;
}

.LocationPro:hover {
  color: var(--themeColor) !important;
}
