.PhotoSectionSliderContainer {
  height: fit-content;
  position: relative;
}

.PhotoSectionSliderContainer .PhotoSectionImageContainer {
  position: relative;
  margin: 0px 5px 0px 5px;
}

.PhotoSectionSliderContainer
  .PhotoSectionImageContainer
  .PhotoSectionSliderImage {
  /* width: 200px !important; */
  height: 127px;
  width: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 1000px) {
  .PhotoSectionSliderImage {
    height: 200px !important;
  }
}

.PhotoSectionSliderContainer
  .PhotoSectionImageContainer
  .PhotoSectionImageBackDrop {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.581);
  z-index: 10;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
}

.PhotoSectionSliderContainer
  .PhotoSectionImageContainer
  .PhotoSectionImageBackDropUnmount {
  background-color: rgba(0, 0, 0, 0) !important;
}

.PhotoSectionSliderContainer
  .PhotoSectionImageContainer
  .PhotoSectionImageBackDrop
  .PhotoArrowDecoration {
  width: 50%;
  height: 50%;

  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  margin: auto;
  animation-name: arrowAnnimation;
  animation-duration: 0.5s;
}
.PhotoSectionSliderContainer
  .PhotoSectionImageContainer
  .PhotoSectionImageBackDrop
  .handelPhotoArrowDecorationUnmount {
  width: 50%;
  height: 50%;

  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  margin: auto;
  animation-name: arrowAnnimationUnmount;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}



.nextArrow::before {
  content: "Next\2192";
  font-family: monospace;
  color: var(--themeColor);
}
.prevArrow::before {
  content: "\2190Prev";
  font-family: monospace;
  color: var(--themeColor);
}

@media only screen and (min-width: 1000px) {
  .prevArrow {
    bottom: -167px !important;
    left: 0px !important;
  }
  

  
  .nextArrow {
    bottom: -20px !important;
    left: 100px !important;
  }
  

}
@media only screen and (max-width: 1000px) {
  .prevArrow {
    bottom: -240px !important;
    left: 0px !important;
  }
  

  
  .nextArrow {
    bottom: -20px !important;
    left: 100px !important;
  }
  

}